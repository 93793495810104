import React from 'react';

class ToggleButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showTooltip: true });
        }, 8000);
    }


    render() {
        return (
            this.props.widgetIsVisible && (
                <div className="widget-button" style={this.props.css} onClick={() => this.props.toggleWidget()}>
                    {
                        !this.props.hideTooltip && this.props.tooltipMessage && !this.props.widgetIsOpen && (
                            <div className={`tooltip ${this.state.showTooltip ? 'visible': 'hidden'}`}>
                                {this.props.tooltipMessage}
                            </div>
                        )
                    }
                    {this.props.widgetIsOpen ? (
                        <div className="icon-wrapper close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.167" height="22.167" viewBox="0 0 22.167 22.167">
                                <path id="Path_3157" data-name="Path 3157" d="M27.167,7.233,24.934,5l-8.851,8.851L7.233,5,5,7.233l8.851,8.851L5,24.934l2.233,2.233,8.851-8.851,8.851,8.851,2.233-2.233-8.851-8.851Z" transform="translate(-5 -5)" fill="#fff" />
                            </svg>
                        </div>
                    ) : (
                            <div className="icon-wrapper" dangerouslySetInnerHTML={{ __html: this.props.selectedIcon.svg ? this.props.selectedIcon.svg : '' }}></div>
                        )}
                </div>
            )
        );
    }
}

export default ToggleButton;