import React from 'react';
import axios from "axios";
import widgetIcons from '../utils/icons';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import ToggleButton from './toggleButton';
import FaqsList from './faqsList';
import HomeScreen from './homeScreen';
import Chat from './chat';

class Widget extends React.Component {

    constructor(props) {
        super(props);

        const queryParameters = new URLSearchParams(window.location.search);
        const isEmbedded = !!(queryParameters.get("embedded") === 'true');

        this.isEmbedded = isEmbedded;

        this.state = {
            widgetId: queryParameters.get("widget-id"),
            embedded: isEmbedded,
            icons: widgetIcons,
            selectedIcon: {},
            widgetIsOpen: false,
            widgetIsVisible: false,
            chatSectionIsVisible: false,
            faqsSectionIsVisible: false,
        };

        this.styles = {};
        this.getDataFlag = false;

    }

    componentDidMount() {

        if (!this.getDataFlag) {

            axios
            .get(`https://us-central1-motherfaqr-dev.cloudfunctions.net/getData?widget-id=${this.state.widgetId}`)
            .then(response => {
                const data = response.data;
                this.setState({
                    widgetSettings: data.widgetSettings,
                    categories: data.categories,
                    questions: data.questions
                }, () => {
                    this.createWidgetStyle(this.state.widgetSettings);
                    this.checkWidgetVisibility(this.state.widgetSettings);
                })
            })
            .catch(() => {
                this.setState({
                    chatSectionIsVisible: false,
                    faqsSectionIsVisible : false,
                    widgetIsVisible: false
                });
            });

            this.getDataFlag = true;

        }

        window.addEventListener('message', (event) => {

            if (event.data.type === 'resize') {

                document.documentElement.style.setProperty('--widget-wrapper-height', `${event.data.height - 20 - this.defaultIframeHeight + this.calculateHeight(this.state?.widgetSettings?.iconSettings?.widgetTooltip, 12 ,20, 140, 8)}px`);

                if (event.data.isMobile) {
                    this.setState({
                        isMobile: true
                    })
                } else {
                    this.setState({
                        isMobile: false
                    })
                }

            }

        });

    }



    checkWidgetVisibility = (data) => {

        const chatSectionIsVisible = !!(data.chatSection.visible === true || data.chatSection.visible === 'true');
        const faqsSectionIsVisible = !!((data.faqsSection.visible === true || data.faqsSection.visible === 'true') && this.state.questions.question.length);

        let widgetIsOpen, widgetIsVisible;

        if(this.isEmbedded) {
            widgetIsOpen = true;
            widgetIsVisible = true;

        }

        this.setState({
            chatSectionIsVisible,
            faqsSectionIsVisible,
            widgetIsOpen,
            widgetIsVisible,
            widgetIsVisible: !!(chatSectionIsVisible || faqsSectionIsVisible)
        });

    }

    hexToRGBA = (hex, opacity) => {
        let hexValue = hex.replace('#', '');

        // Pad the hex value if it has less than 6 characters
        if (hexValue.length < 6) {
            hexValue = hexValue.padEnd(6, '0');
        }

        const r = parseInt(hexValue.substring(0, 2), 16);
        const g = parseInt(hexValue.substring(2, 4), 16);
        const b = parseInt(hexValue.substring(4, 6), 16);
        const alpha = parseFloat(opacity);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };


    createWidgetStyle = (data) => {

        document.documentElement.style.setProperty('--widget-body-background-color', data.bodySettings.backgroundColor);
        document.documentElement.style.setProperty('--primary-color', this.state.widgetSettings.textSettings.primaryColor);
        document.documentElement.style.setProperty('--secondary-color', this.state.widgetSettings.textSettings.secondaryColor);
        document.documentElement.style.setProperty('--accent-color', this.state.widgetSettings.textSettings.tertiaryColor);
        document.documentElement.style.setProperty('--accent-color-opacity-30', this.hexToRGBA(this.state.widgetSettings.textSettings.tertiaryColor, .30));
        document.documentElement.style.setProperty('--box-shadow-color', this.hexToRGBA(this.state.widgetSettings.textSettings.primaryColor, .16));
        document.documentElement.style.setProperty('--message-received-background', this.hexToRGBA(this.state.widgetSettings.textSettings.primaryColor, .05));
        document.documentElement.style.setProperty('--input-default-color', this.hexToRGBA(this.state.widgetSettings.textSettings.primaryColor, .16));
        document.documentElement.style.setProperty('--input-hover-color', this.hexToRGBA(this.state.widgetSettings.textSettings.primaryColor, .26));
        document.documentElement.style.setProperty('--input-active-color', this.hexToRGBA(this.state.widgetSettings.textSettings.primaryColor, .50));
        document.documentElement.style.setProperty('--code-background-color', this.hexToRGBA(this.state.widgetSettings.textSettings.primaryColor, .05));
        document.documentElement.style.setProperty('--widget-button-background-color', data.iconSettings.backgroundColor);
        document.documentElement.style.setProperty('--widget-button-text-color', data.iconSettings.iconColor);

        document.documentElement.style.setProperty('--widget-header-background-color', data.headerSettings.backgroundColor);
        document.documentElement.style.setProperty('--widget-header-text-color', data.headerSettings.textColor);

        this.styles.icon = {};
        this.styles.widget = {};

        let iframePositionLeft;
        let iframePositionRight;
        this.defaultIframeWidth = '';
        this.defaultIframeHeight = '';
        this.iframeWidthOpened = '';
        this.iframeHeightOpened = '';

        if (data.widgetPosition.position === 'bottom-left') {
            this.styles.icon.marginLeft = parseInt(data.widgetPosition.margins.left);
            this.styles.icon.marginBottom = parseInt(data.widgetPosition.margins.bottom);
            this.styles.icon.left = 0;
            this.styles.icon.bottom = 0;

            
            this.defaultIframeWidth = (this.state?.widgetSettings?.iconSettings?.widgetTooltip ? 140 : 50) + (parseInt(data.widgetPosition.margins.left) * 2);
            this.defaultIframeHeight = 50 + (parseInt(data.widgetPosition.margins.bottom) * 2) + this.calculateHeight(this.state?.widgetSettings?.iconSettings?.widgetTooltip, 12 ,20, 140, 8);

            this.iframeWidthOpened = 320 + (parseInt(data.widgetPosition.margins.left) * 2);
            this.iframeHeightOpened = 480 + (parseInt(data.widgetPosition.margins.bottom) * 3);

            iframePositionLeft = '0';
            iframePositionRight = 'auto';

            if (!this.state.embedded) {

                this.styles.widget.marginLeft = parseInt(data.widgetPosition.margins.left);
                this.styles.widget.left = 0;
                this.styles.widget.bottom = 50 + (parseInt(data.widgetPosition.margins.bottom) + 20);

            }

        } else if (data.widgetPosition.position === 'bottom-right') {
            this.styles.icon.marginRight = parseInt(data.widgetPosition.margins.right);
            this.styles.icon.marginBottom = parseInt(data.widgetPosition.margins.bottom);
            this.styles.icon.right = 0;
            this.styles.icon.bottom = 0;


            this.defaultIframeWidth = (this.state?.widgetSettings?.iconSettings?.widgetTooltip ? 140 : 50) + (parseInt(data.widgetPosition.margins.right) * 2);
            this.defaultIframeHeight = 50 + (parseInt(data.widgetPosition.margins.bottom) * 2) + this.calculateHeight(this.state?.widgetSettings?.iconSettings?.widgetTooltip, 12 ,20, 140, 8);

            this.iframeWidthOpened = 320 + (parseInt(data.widgetPosition.margins.right) * 2);
            this.iframeHeightOpened = 480 + (parseInt(data.widgetPosition.margins.bottom) * 3);

            iframePositionLeft = 'auto';
            iframePositionRight = '0';

            if (!this.state.embedded) {

                this.styles.widget.marginRight = parseInt(data.widgetPosition.margins.right);
                this.styles.widget.right = 0;
                this.styles.widget.bottom = 50 + (parseInt(data.widgetPosition.margins.bottom) + 20);

            }

        }

        document.documentElement.style.setProperty('--widget-wrapper-height', `${this.iframeHeightOpened - 20 - this.defaultIframeHeight + this.calculateHeight(this.state?.widgetSettings?.iconSettings?.widgetTooltip, 12 ,20, 140, 8)}px`);

        const selectedIcon = this.state.icons.find(icon => icon.name === data.iconSettings.iconType)
        this.setState({
            selectedIcon: selectedIcon,
        });

        const postMessageBody = {
            type: 'loaded',
            closedWidth: this.defaultIframeWidth,
            closedHeight: this.defaultIframeHeight,
            openedWidth: this.iframeWidthOpened,
            openedHeight: this.iframeHeightOpened,
            left: iframePositionLeft,
            right: iframePositionRight,
        }

        if (this.state.embedded) {

            postMessageBody.borderRadius = 10;
            postMessageBody.boxShadowColor = this.hexToRGBA(this.state.widgetSettings.textSettings.primaryColor, .16);

        }

        window.parent.postMessage(postMessageBody, '*');

    }

    toggleWidget = () => {
        this.setState({
            widgetIsOpen: !this.state.widgetIsOpen,
            hideTooltip: true
        }, () => {

            window.parent.postMessage(
                {
                    type: 'toggleWidget',
                    opened: this.state.widgetIsOpen
                }, '*');
        });
    }

    calculateHeight = (text, fontSize, lineHeight, width, padding) => {

        if (!text) {

            const height = 0;

            return height;

        }
        const words = text.split(' ');
        let lines = [];
        let currentLine = '';
        
        for (const word of words) {
            const testLine = currentLine === '' ? word : `${currentLine} ${word}`;
            const testWidth = this.calculateTextWidth(testLine, fontSize);
            
            if (testWidth <= width - 2 * padding) {
                currentLine = testLine;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }

        if (currentLine !== '') {
            lines.push(currentLine);
        }

        const calculatedHeight = lines.length * lineHeight + 2 * padding;

        return calculatedHeight;
    };

    calculateTextWidth = (text, fontSize) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = `${fontSize}px Arial`; // Set your desired font style
        const width = context.measureText(text).width;

        return width;
    };



    render() {

        return (
            <>
                {
                    !this.state.embedded ? 
                    <ToggleButton
                        widgetIsVisible={this.state.widgetIsVisible}
                        css={this.styles.icon}
                        toggleWidget={this.toggleWidget}
                        selectedIcon={this.state.selectedIcon}
                        widgetIsOpen={this.state.widgetIsOpen}
                        tooltipMessage={this.state?.widgetSettings?.iconSettings?.widgetTooltip}
                        hideTooltip={this.state.hideTooltip}
                    /> : ''
                }

                {this.state.widgetIsOpen && (
                    <div className={`widget-wraper ${this.state.isMobile && !this.state.embedded ? 'mobile' : '' } ${this.state.embedded ? 'embedded' : ''}`} style={this.styles.widget}>
                        <div className="widget">
                            <div className="widget-body">
                                <Router>
                                    <Route
                                        path="/"
                                        render={({ location }) => {
                                            if (location.pathname === '/') {
                                                return (
                                                    <HomeScreen
                                                        chatSectionIsVisible={this.state.chatSectionIsVisible}
                                                        faqsSectionIsVisible={this.state.faqsSectionIsVisible}
                                                        title={this.state.widgetSettings.headerSettings.headerText}
                                                        toggleWidget={this.toggleWidget}
                                                        widgetId={this.state.widgetId}
                                                        faqsTitle={this.state.widgetSettings.faqsSection.title}
                                                        faqsDescription={this.state.widgetSettings.faqsSection.description}
                                                        chatTitle={this.state.widgetSettings.chatSection.title}
                                                        chatDescription={this.state.widgetSettings.chatSection.description}
                                                    />
                                                );
                                            } else {
                                                return null;
                                            }
                                        }}
                                    />

                                    {
                                        this.state.faqsSectionIsVisible &&
                                        <Route
                                            exact
                                            path="/faqs"
                                            render={(props) => (
                                                <FaqsList
                                                    widgetId={this.state.widgetId}
                                                    categories={this.state.categories}
                                                    questions={this.state.questions}
                                                    sectionTitle={this.state.widgetSettings.faqsSection.title}
                                                    sectionDescription={this.state.widgetSettings.faqsSection.description}
                                                    showBackButton={!!(this.state.chatSectionIsVisible && this.state.faqsSectionIsVisible)}
                                                    embedded={this.state.embedded}
                                                    toggleWidget={this.toggleWidget}
                                                    {...props}
                                                />
                                            )}
                                        />
                                    }

                                    {
                                        this.state.chatSectionIsVisible &&
                                        <Route
                                            exact
                                            path="/chat"
                                            render={(props) => (
                                                <Chat
                                                    widgetId={this.state.widgetId}
                                                    showBackButton={!!(this.state.chatSectionIsVisible && this.state.faqsSectionIsVisible)}
                                                    embedded={this.state.embedded}
                                                    chatSettings={this.state.widgetSettings.chatSection}
                                                    toggleWidget={this.toggleWidget}
                                                    {...props}
                                                    questions={this.state.questions.question}
                                                />
                                            )}
                                        />
                                    }

                                    {
                                        this.state.faqsSectionIsVisible && !this.state.chatSectionIsVisible &&
                                        <Redirect to={`/faqs?widget-id=${this.state.widgetId}`} />
                                    }

                                    {
                                        !this.state.faqsSectionIsVisible && this.state.chatSectionIsVisible &&
                                        <Redirect to={`/chat?widget-id=${this.state.widgetId}`} />
                                    }

                                </Router>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }

}

export default Widget;