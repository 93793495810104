import React from 'react';
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";


class HomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { history } = this.props;
        const queryParameters = new URLSearchParams(window.location.search);
        const animate = queryParameters.get("animate");

        if (animate) {
            setTimeout(() => {
                queryParameters.delete("animate");
                history.replace({ search: queryParameters.toString() });
            }, 200); // Adjust the timeout duration as needed
        }

        this.setState({
            animate
        });

    }

    render() {

        return (
            <div className={`home-screen ${this.state.animate ? 'animate' : ''}`}>
                <div className="header">
                    <p>{this.props.title}</p>
                    <div className="icon-wrapper close" onClick={() => this.props.toggleWidget()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22.167" height="22.167" viewBox="0 0 22.167 22.167">
                            <path id="Path_3157" data-name="Path 3157" d="M27.167,7.233,24.934,5l-8.851,8.851L7.233,5,5,7.233l8.851,8.851L5,24.934l2.233,2.233,8.851-8.851,8.851,8.851,2.233-2.233-8.851-8.851Z" transform="translate(-5 -5)" fill="#fff" />
                        </svg>
                    </div>
                </div>
                <div className="body">
                    <div className="links">

                        {this.props.faqsSectionIsVisible &&
                            <Link to={`/faqs?widget-id=${this.props.widgetId}&animate=true`}>
                                {
                                    this.props.faqsTitle &&
                                    <h2>
                                        {this.props.faqsTitle}
                                        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m699.37-292.391-48.979-47.979 104.565-104.565H115.935v-68.13h640.021L651.152-617.63l47.978-47.979L885.739-479 699.37-292.391Z" /></svg>
                                    </h2>
                                }
                                {
                                    this.props.faqsDescription &&
                                    <p>{this.props.faqsDescription}</p>
                                }
                            </Link>}

                        {

                            this.props.chatSectionIsVisible &&
                            <Link to={`/chat?widget-id=${this.props.widgetId}&animate=true`}>
                                {
                                    this.props.chatTitle &&
                                    <h2>
                                        {this.props.chatTitle}
                                        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m699.37-292.391-48.979-47.979 104.565-104.565H115.935v-68.13h640.021L651.152-617.63l47.978-47.979L885.739-479 699.37-292.391Z" /></svg>
                                    </h2>
                                }
                                {
                                    this.props.chatDescription &&
                                    <p>{this.props.chatDescription}</p>
                                }
                            </Link>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(HomeScreen);