import React from 'react';
import axios from "axios";

class SearchComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            results: [],
            isLoading: false,
        };
        this.timer = null;
    }

    componentDidMount() {
        this.debounceAPICall();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.searchTerm !== this.state.searchTerm) {
            this.debounceAPICall();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleInputChange = (event) => {
        this.setState({ searchTerm: event.target.value });
    };

    handleAPICall = () => {
        // Make your API call here using this.state.searchTerm
        // Replace the setTimeout function with your actual API call

        axios
            .get(`https://us-central1-motherfaqr-dev.cloudfunctions.net/searchQuestionsAndAnswers?widget-id=${this.props.widgetId}&keyword=${this.state.searchTerm}`)
            .then(response => {
                this.setState({
                    results: response.data,
                    isLoading: false,
                })
            })
            .catch(error => {

            });

    };

    debounceAPICall = () => {
        clearTimeout(this.timer);
        if (this.state.searchTerm && this.state.searchTerm.length > 2) {
            this.setState({
                isLoading: true,
            });
            this.timer = setTimeout(this.handleAPICall, 500);
        } else {
            if (!this.state.searchTerm) { // Check if search term is empty
                this.setState({
                    results: [],
                });
            }
            this.setState({
                isLoading: false, // Set isLoading to false when search term is less than 3 characters
            });
        }
    };


    clearSearch = () => {
        this.setState({
            results: [],
            searchTerm: ''
        });
    }

    render() {
        const { searchTerm } = this.state;

        return (
            <div className="search-input">
                <div className="input-wrapper">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={this.handleInputChange}
                        placeholder="Search..."
                    />
                    <span className="input-icon">
                        {
                            this.state.isLoading
                                ?
                                <div className="loader" />
                                : ''}
                        {
                            !this.state.searchTerm && !this.state.isLoading
                                ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <path id="search_FILL1_wght700_GRAD200_opsz48" d="M23.311,25.25l-7.062-7.062a6.2,6.2,0,0,1-1.805.949,6.923,6.923,0,0,1-2.227.343,6.929,6.929,0,0,1-5.1-2.069A6.866,6.866,0,0,1,5.05,12.365,7.063,7.063,0,0,1,12.165,5.25a7.063,7.063,0,0,1,7.115,7.115,6.685,6.685,0,0,1-.343,2.2,7.1,7.1,0,0,1-1,1.884l7.115,7.062ZM12.165,17.081a4.636,4.636,0,0,0,4.717-4.717,4.575,4.575,0,0,0-1.357-3.347,4.509,4.509,0,0,0-3.333-1.37,4.658,4.658,0,0,0-4.743,4.717,4.636,4.636,0,0,0,4.717,4.717Z" transform="translate(-5.05 -5.25)" fill="#575f7f" />
                                </svg>
                                : ''}
                        {
                            this.state.searchTerm && !this.state.isLoading
                                ?
                                <div className="clear-search" onClick={() => this.clearSearch()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M256.478 950.131q-33.49 0-56.637-22.981-23.147-22.98-23.147-56.237V310.522h-50.609v-79.218h212.306v-40.175h282.653v40.175h212.871v79.218h-50.609v560.391q0 32.507-23.522 55.862-23.522 23.356-56.262 23.356H256.478Zm447.044-639.609H256.478v560.391h447.044V310.522Zm-343.87 478.913h69.609v-399h-69.609v399Zm171.087 0h70.174v-399h-70.174v399ZM256.478 310.522v560.391-560.391Z" /></svg>
                                </div>
                                : ''}
                    </span>
                </div>
                {
                    this.state.searchTerm && this.state.results.length === 0 && !this.state.isLoading ?
                        <p className="no-results">No results found</p> : ''
                }
                <div className={`search-results ${this.state.results.length ? 'visible' : ''}`}>
                    {this.state.results.map((question, index) => (
                        <div className="question hover-opacity-effect" onClick={() => this.props.openQuestion(question)} key={index}>
                            {question.question}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default SearchComponent;