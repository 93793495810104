import React from 'react';
import { Link, withRouter } from "react-router-dom";
import axios from 'axios';

class Chat extends React.Component {
    constructor(props) {
        super(props);

        const currentDate = new Date();
        this.formattedDate = this.formatDate(currentDate);

        let sessionSettings;
        try {
            sessionSettings = sessionStorage.getItem('mfqrSessionSettings');
        } catch (error) {
            // console.error('Error accessing sessionStorage:', error);
        }

        // const sessionSettings = sessionStorage.getItem('mfqrSessionSettings');

        this.sessionSettingsObj = '';

        if (!sessionSettings) {

            this.sessionSettingsObj = {
                widgetId: this.props.widgetId,
                conversationId: this.generateConversationId(),
                chatMessages: {}
            }

            this.sessionSettingsObj.chatMessages[this.formattedDate] = [
                {
                    completion: this.props.chatSettings.welcomeMessage,
                    role: "assistant",
                    received: true,
                    timestamp: new Date().toLocaleTimeString(),
                    feedback: ''
                }
            ]

            try {
                sessionStorage.setItem('mfqrSessionSettings', JSON.stringify(this.sessionSettingsObj));
            } catch (error) {
                // console.error('Error writing to session storage:', error);
            }

        }

        if (sessionSettings) {

            this.sessionSettingsObj = JSON.parse(sessionSettings);

            if (!this.sessionSettingsObj.widgetId || this.sessionSettingsObj.widgetId !== this.props.widgetId) {

                this.sessionSettingsObj.widgetId = this.props.widgetId;
                this.sessionSettingsObj.chatMessages[this.formattedDate] = [
                    {
                        completion: this.props.chatSettings.welcomeMessage,
                        role: "assistant",
                        received: true,
                        timestamp: new Date().toLocaleTimeString(),
                        feedback: '',
                    }
                ]

            }

            try {
                sessionStorage.setItem('mfqrSessionSettings', JSON.stringify(this.sessionSettingsObj));
            } catch (error) {
                // console.error('Error writing to session storage:', error);
            }

        }

        this.state = {
            sessionSettingsObj: this.sessionSettingsObj,
            email: '',
            emailErrorMessage: '',
            showChatInput: true,
            chatMessage: '',
        };

        this.chatBodyRef = React.createRef();
    }

    formatDate = (date) => {
        const isoString = date.toISOString();
        const localDate = new Date(isoString);
        const day = String(localDate.getDate()).padStart(2, '0');
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const year = localDate.getFullYear();
        return `${day}/${month}/${year}`;
    }

    // componentDidUpdate(prevProps, prevState) {
    //     // if (prevState.ses.chatMessages.length < this.state.chatMessages.length) {
    //     //     this.scrollToBottom();
    //     // }
    // }

    scrollToBottom = () => {
        this.chatBodyRef.current.scrollTop = this.chatBodyRef.current.scrollHeight;
    };

    componentDidMount() {
        const { history } = this.props;
        const queryParameters = new URLSearchParams(window.location.search);
        const animate = queryParameters.get("animate");

        if (animate) {
            setTimeout(() => {
                queryParameters.delete("animate");
                history.replace({ search: queryParameters.toString() });
            }, 200); // Adjust the timeout duration as needed
        }

        this.setState({
            animate
        }, () => {
            this.scrollToBottom();
        });

    }

    sendMessage = (message) => {

        if (!message || this.state.isSubmitting) {
            return;
        }

        const content = message ? message : 'hey there';
        let sessionSettingsObjClone = { ...this.state.sessionSettingsObj };

        if (sessionSettingsObjClone.chatMessages[this.formattedDate]) {

            sessionSettingsObjClone.chatMessages[this.formattedDate].push({
                completion: message,
                sent: true,
                timestamp: new Date().toLocaleTimeString(),
                feedback: '',
            });

        } else {

            sessionSettingsObjClone.chatMessages[this.formattedDate] = [];

            sessionSettingsObjClone.chatMessages[this.formattedDate].push({
                completion: message,
                sent: true,
                timestamp: new Date().toLocaleTimeString(),
                feedback: '',
            });

        }

        this.setState({
            sessionSettingsObj: sessionSettingsObjClone,
            isSubmitting: true,
            feedbackSent: false,
            chatMessage: '',
            email: '',
        }, () => {
            if (!this.state.askForEmail) {
                try {
                    sessionStorage.setItem('mfqrSessionSettings', JSON.stringify(this.sessionSettingsObj));
                } catch (error) {
                    // console.error('Error writing to session storage:', error);
                }
            }
            this.scrollToBottom();
        });

        // const relevantFAQs = this.findMostRelevantFAQs(content, this.props.questions, 4000);

        const payload = {
            message: {
                'role': 'user',
                'content': content
            },
            messagesHistory: this.state.sessionSettingsObj,
            localDate: this.formattedDate,
            // localDate: currentDate.toLocaleString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' }),
            reset: this.state.askForEmail ? true : false,
        }

        if (this.state.askForEmail) {
            payload.userDetails = {
                email: message
            }
        }

        axios
            .post(`https://us-central1-motherfaqr-dev.cloudfunctions.net/faqsChat?widget-id=${this.props.widgetId}`, payload)
            .then((response) => {
                // if (!this.state.askForEmail) {

                response.data.received = true;
                response.data.timestamp = new Date().toLocaleTimeString();
                response.data.feedback = '';

                let sessionSettingsObjClone = { ...this.state.sessionSettingsObj };

                if (sessionSettingsObjClone.chatMessages[this.formattedDate]) {

                    sessionSettingsObjClone.chatMessages[this.formattedDate].push(response.data);

                } else {

                    sessionSettingsObjClone.chatMessages[this.formattedDate] = [];
                    sessionSettingsObjClone.chatMessages[this.formattedDate].push(response.data);

                }

                // }

                // const coversation = [];
                this.setState({
                    sessionSettingsObj: sessionSettingsObjClone,
                    isSubmitting: false,
                    showChatInput: this.state.askForEmail ? false : true
                }, () => {
                    if (!this.state.askForEmail) {
                        try {
                            sessionStorage.setItem('mfqrSessionSettings', JSON.stringify(this.sessionSettingsObj));
                        } catch (error) {
                            // console.error('Error writing to session storage:', error);
                        }
                    }

                    this.scrollToBottom();

                });

            }).catch((error) => {

                try {
                    sessionStorage.removeItem('mfqrSessionSettings');
                } catch (error) {
                    // console.error('Error writing to session storage:', error);
                }

            });
    };

    updateChatMessage = (message) => {

        this.setState({
            chatMessage: message
        });

    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter' && !this.state.isSubmitting) {
            this.sendMessage(this.state.chatMessage);
            event.target.value = '';
        }
    };

    generateConversationId = () => {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 10000);
        return `${timestamp}_${random}`;
    }

    sendFeedback = (value) => {
        if (value === 'like') {
            // Hide the feedback component
            this.setState({
                feedbackSent: true,
                showSystemMessage: false, // Hide the system message (email prompt) if it was previously shown
            }, () => {
                // Set the chatMessage feedback to 'like' for the last message
                this.setState((prevState) => {
                    const { sessionSettingsObj } = prevState;
                    const chatMessages = sessionSettingsObj.chatMessages;
                    const lastDate = Object.keys(chatMessages).pop();
                    const lastMessages = chatMessages[lastDate];
                    const lastMessage = lastMessages[lastMessages.length - 1];

                    const updatedLastMessage = { ...lastMessage, feedback: 'like' };
                    lastMessages[lastMessages.length - 1] = updatedLastMessage;

                    return {
                        sessionSettingsObj: {
                            ...sessionSettingsObj,
                            chatMessages: {
                                ...chatMessages,
                                [lastDate]: lastMessages,
                            },
                        },
                    };
                }, () => {
                    try {
                        sessionStorage.setItem('mfqrSessionSettings', JSON.stringify(this.state.sessionSettingsObj));
                    } catch (error) {
                        // console.error('Error writing to session storage:', error);
                    }
                    this.scrollToBottom();
                });
            });
        }

        if (value === 'dislike') {
            // Hide the feedback component and show another message
            this.setState({
                feedbackSent: true,
                showSystemMessage: false,
                askForEmail: true,
            }, () => {

                const message = this.props.chatSettings.askForTheEmailPrompt;

                let sessionSettingsObjClone = { ...this.state.sessionSettingsObj };

                if (sessionSettingsObjClone.chatMessages[this.formattedDate]) {
                    sessionSettingsObjClone.chatMessages[this.formattedDate].push({
                        completion: message,
                        received: true,
                        timestamp: new Date().toLocaleTimeString(),
                        feedback: 'dislike',
                    });
                } else {
                    sessionSettingsObjClone.chatMessages[this.formattedDate] = [];
                    sessionSettingsObjClone.chatMessages[this.formattedDate].push({
                        completion: message,
                        received: true,
                        timestamp: new Date().toLocaleTimeString(),
                        feedback: 'dislike',
                    });
                }

                this.setState({
                    sessionSettingsObj: sessionSettingsObjClone,
                }, () => {
                    try {
                        sessionStorage.setItem('mfqrSessionSettings', JSON.stringify(this.state.sessionSettingsObj));
                    } catch (error) {
                        // console.error('Error writing to session storage:', error);
                    }
                    this.scrollToBottom();

                    try {
                        sessionStorage.removeItem('mfqrSessionSettings');
                    } catch (error) {
                        // console.error('Error writing to session storage:', error);
                    }

                });
            });
        }
    }

    handleEmailKeyPress = (event) => {
        if (event.key === 'Enter' && !this.state.isSubmitting) {
            if (this.isEmailValid(this.state.email)) {
                this.sendMessage(this.state.email);
                // Clear the email input value after sending
                this.setState({ email: '' });
            } else {
                this.setState({ emailErrorMessage: 'Invalid email address' });
            }
        }
    };

    isEmailValid = (email) => {
        // Basic email validation using regex
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    render() {
        return (
            <div className={`chat ${this.state.animate ? 'animate' : ''}`}>
                <div className="chat-header">
                    {
                        this.props.showBackButton &&
                        <Link className="back-button" to={`/?widget-id=${this.props.widgetId}&animate=true`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <path id="arrow_back_FILL0_wght600_GRAD0_opsz48" d="M160.782,263.782l-7-7,7-7,1.2,1.192-4.946,4.958h10.741v1.7H157.041l4.946,4.946Z" transform="translate(-153.782 -249.782)" />
                            </svg>
                        </Link>
                    }
                    {
                        !this.props.showBackButton && !this.props.embedded &&
                        <div className="back-button" onClick={() => {this.props.toggleWidget()}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <path id="arrow_back_FILL0_wght600_GRAD0_opsz48" d="M160.782,263.782l-7-7,7-7,1.2,1.192-4.946,4.958h10.741v1.7H157.041l4.946,4.946Z" transform="translate(-153.782 -249.782)" />
                            </svg>
                        </div>
                    }
                    {
                        this.props.chatSettings.avatarUrl &&
                        <div className="avatar"
                            style={{
                                backgroundImage: `url(${this.props.chatSettings.avatarUrl})`,
                            }}
                        />
                    }
                    {
                        this.props.chatSettings.assistantName &&
                        <h3 className="asistant-name">
                            {this.props.chatSettings.assistantName}
                        </h3>
                    }
                </div>

                <div className="chat-body" ref={this.chatBodyRef}>
                    {Object.keys(this.state.sessionSettingsObj.chatMessages).map((key, outerIndex, outerArray) => (
                        <React.Fragment key={key}>
                            <div className="session-date">{key}</div>
                            {this.state.sessionSettingsObj.chatMessages[key].map((message, index, array) => (
                                <div className={`message-wrapper ${message.sent ? 'sent' : ''} ${message.received ? 'received' : ''}`} key={index}>
                                    <div className="message" dangerouslySetInnerHTML={{ __html: message.completion }}></div>
                                    <span className="message-timestamp">
                                        {message.sent ? 'You' : this.props.chatSettings.assistantName} - {message.timestamp}
                                    </span>
                                    {outerIndex === outerArray.length - 1 && index === array.length - 1 && message.received && index > 0 && !this.state.feedbackSent && !this.state.askForEmail && (
                                        <div className="feedback">
                                            <p>Was this answer helpful?</p>
                                            <div className="actions-wrapper">
                                                <div className="action thumb-up" onClick={() => { this.sendFeedback('like') }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                        <path id="recommend_FILL1_wght400_GRAD0_opsz48" d="M87-864.65h5.75a1.08,1.08,0,0,0,.588-.15,1.279,1.279,0,0,0,.412-.45l1.95-4.55a1.79,1.79,0,0,0,.088-.375,2.776,2.776,0,0,0,.037-.375v-.6a.7.7,0,0,0-.162-.512.7.7,0,0,0-.512-.163H89.8l.725-3.45a.715.715,0,0,0,0-.375.682.682,0,0,0-.175-.3l-.525-.55L85.8-872.15l-.2.4a.938.938,0,0,0-.1.425v5.175a1.453,1.453,0,0,0,.45,1.038A1.421,1.421,0,0,0,87-864.65ZM90-860a9.677,9.677,0,0,1-3.875-.787,10.144,10.144,0,0,1-3.187-2.15,10.143,10.143,0,0,1-2.15-3.187A9.676,9.676,0,0,1,80-870a9.738,9.738,0,0,1,.787-3.9,9.995,9.995,0,0,1,2.15-3.175,10.252,10.252,0,0,1,3.188-2.138A9.677,9.677,0,0,1,90-880a9.738,9.738,0,0,1,3.9.787,10.1,10.1,0,0,1,3.175,2.138,10.1,10.1,0,0,1,2.138,3.175A9.738,9.738,0,0,1,100-870a9.676,9.676,0,0,1-.787,3.875,10.251,10.251,0,0,1-2.138,3.188,9.994,9.994,0,0,1-3.175,2.15A9.738,9.738,0,0,1,90-860Z" transform="translate(-80 880)" />
                                                    </svg>
                                                </div>
                                                <div className="action thumb-down" onClick={() => { this.sendFeedback('dislike') }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                        <path id="recommend_FILL1_wght400_GRAD0_opsz48" d="M87-864.65h5.75a1.08,1.08,0,0,0,.588-.15,1.279,1.279,0,0,0,.412-.45l1.95-4.55a1.79,1.79,0,0,0,.088-.375,2.776,2.776,0,0,0,.037-.375v-.6a.7.7,0,0,0-.162-.512.7.7,0,0,0-.512-.163H89.8l.725-3.45a.715.715,0,0,0,0-.375.682.682,0,0,0-.175-.3l-.525-.55L85.8-872.15l-.2.4a.938.938,0,0,0-.1.425v5.175a1.453,1.453,0,0,0,.45,1.038A1.421,1.421,0,0,0,87-864.65ZM90-860a9.677,9.677,0,0,1-3.875-.787,10.144,10.144,0,0,1-3.187-2.15,10.143,10.143,0,0,1-2.15-3.187A9.676,9.676,0,0,1,80-870a9.738,9.738,0,0,1,.787-3.9,9.995,9.995,0,0,1,2.15-3.175,10.252,10.252,0,0,1,3.188-2.138A9.677,9.677,0,0,1,90-880a9.738,9.738,0,0,1,3.9.787,10.1,10.1,0,0,1,3.175,2.138,10.1,10.1,0,0,1,2.138,3.175A9.738,9.738,0,0,1,100-870a9.676,9.676,0,0,1-.787,3.875,10.251,10.251,0,0,1-2.138,3.188,9.994,9.994,0,0,1-3.175,2.15A9.738,9.738,0,0,1,90-860Z" transform="translate(100 -860) rotate(180)" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {
                                        this.state.feedbackSent && this.state.showSystemMessage &&
                                        <p className="system-message">{this.props.chatSettings.askForTheEmailPrompt}</p>
                                    }
                                </div>
                            ))}
                        </React.Fragment>

                    ))}

                    {this.state.isSubmitting && (
                        <span className="typing-animation">
                            <span className="typing-bullet"></span>
                            <span className="typing-bullet"></span>
                            <span className="typing-bullet"></span>
                        </span>
                    )}
                </div>

                {
                    this.state.showChatInput && (
                        <div className={`chat-input ${this.state.emailErrorMessage ? 'error' : ''}`} >
                            {this.state.askForEmail ? (
                                <>
                                    <input
                                        type="email"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({ email: e.target.value, chatMessage: e.target.value })}
                                        onKeyPress={this.handleEmailKeyPress}
                                        placeholder="Your email address"
                                    />
                                    {this.state.emailErrorMessage && (
                                        <p className="error-message">{this.state.emailErrorMessage}</p>
                                    )}
                                </>
                            ) : (
                                    <input
                                        type="text"
                                        onChange={(e) => this.updateChatMessage(e.currentTarget.value)}
                                        onKeyPress={this.handleKeyPress}
                                        value={this.state.chatMessage}
                                        placeholder="Type your message here..."
                                    />
                                )}

                            {
                                !this.state.isSubmitting &&
                                <span className="send-button" onClick={() => { this.sendMessage(this.state.chatMessage) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.209" height="12" viewBox="0 0 14.209 12">
                                        <path id="send_FILL1_wght600_GRAD0_opsz48" d="M107-801.566V-806l5.778-1.563-5.778-1.6v-4.4l14.209,6Z" transform="translate(-106.999 813.566)" fill="#5992fe" />
                                    </svg>
                                </span>

                            }
                        </div>

                    )
                }
            </div>
        );
    }
}

export default withRouter(Chat);