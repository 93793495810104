import React from 'react';
import SearchComponent from './SearchComponent';
import { Link } from "react-router-dom";

class FaqsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionOverlayOpened: false,
            currentQuestion: undefined,
        };
        this.toggleQuestions = this.toggleQuestions.bind(this);
    }

    toggleQuestions(event) {
        const categoryWrapper = event.target.parentNode;
        const questionsWrapper = categoryWrapper.querySelector(".toggle");
        const title = categoryWrapper.querySelector(".category-name");
        const description = categoryWrapper.querySelector(".category-description");
        if (questionsWrapper) {
            questionsWrapper.classList.toggle("visible");
        }
        if (title) {
            title.classList.toggle("opened");
        }
        if (description) {
            description.classList.toggle("visible");
        }
    }

    openQuestion = (question) => {
        this.setState({
            currentQuestion: question,
            questionOverlayOpened: true
        })
    }

    closeQuestion() {
        this.setState({
            questionOverlayOpened: false
        }, () => {
            setTimeout(() => {
                this.setState({
                    currentQuestion: ''
                })
            }, 400);
        });
    }

    componentDidMount() {
        const { history } = this.props;
        const queryParameters = new URLSearchParams(window.location.search);
        const animate = queryParameters.get("animate");

        if (animate) {
            setTimeout(() => {
                queryParameters.delete("animate");
                history.replace({ search: queryParameters.toString() });
            }, 200); // Adjust the timeout duration as needed
        }

        this.setState({
            animate
        });
    }


    render() {

        return (
            <>
                <div className={`${this.state.questionOverlayOpened ? 'overflow-hidden' : ''}`}>

                    <div className={`faqs-wrapper ${this.state.questionOverlayOpened ? 'question-modal-opened' : ''} ${this.state.animate ? 'animate' : ''}`}>

                    {
                        this.props.showBackButton &&
                        <Link className="back-button" to={`/?widget-id=${this.props.widgetId}&animate=true`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <path id="arrow_back_FILL0_wght600_GRAD0_opsz48" d="M160.782,263.782l-7-7,7-7,1.2,1.192-4.946,4.958h10.741v1.7H157.041l4.946,4.946Z" transform="translate(-153.782 -249.782)" />
                            </svg>
                        </Link>
                    }
                    {
                        this.props.sectionTitle &&
                        <h2 className="section-title">
                            {this.props.sectionTitle}
                        </h2>
                    }

                    {
                        this.props.sectionDescription &&
                        <p className="section-description">
                            {this.props.sectionDescription}
                        </p>
                    }

                    <SearchComponent widgetId={this.props.widgetId} openQuestion={this.openQuestion} />
                    <div className="body">
                        {this.props.categories && this.props.questions ? (
                            this.props.categories.category.map((category, index) => {
                                const categoryQuestions = this.props.questions.question.filter((question) => question.categoryId === category.id);
                                if (categoryQuestions.length === 0) {
                                    return null;
                                }
                                return (
                                    <div className="category-wrapper" key={index}>
                                        <h3 className="category-name hover-opacity-effect" onClick={this.toggleQuestions}>
                                            {category.name}
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                                                    <path id="Path_3159" data-name="Path 3159" d="M16,11.286H11.286V16H9.714V11.286H5V9.714H9.714V5h1.571V9.714H16Z" transform="translate(-5 -5)" />
                                                </svg>
                                            </span>
                                        </h3>
                                        {category.description && <p className="category-description">{category.description}</p>}
                                        <div className="questions-wrapper toggle">
                                            {categoryQuestions.map((question, index) => (
                                                <div className="question hover-opacity-effect" onClick={() => this.openQuestion(question)} key={index}>
                                                    {question.question}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })
                        ) : null}
                        {this.props.categories && this.props.questions ? (
                            this.props.questions.question.find((q) => q.categoryId === '') && (
                                <div className="category-wrapper no-category">
                                    <div className="questions-wrapper">
                                        {this.props.questions.question.map((question, index) =>
                                            question.categoryId === '' ? (
                                                <div className="question hover-opacity-effect" key={index} onClick={() => this.openQuestion(question)}>
                                                    {question.question}
                                                </div>
                                            ) : null
                                        )}
                                    </div>
                                </div>
                            )
                        ) : null}
                    </div>

                    <div className={`question-modal ${this.state.questionOverlayOpened ? 'opened' : ''}`}>
                        <span className="back-button" onClick={() => this.closeQuestion()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <path id="arrow_back_FILL0_wght600_GRAD0_opsz48" d="M160.782,263.782l-7-7,7-7,1.2,1.192-4.946,4.958h10.741v1.7H157.041l4.946,4.946Z" transform="translate(-153.782 -249.782)" />
                            </svg>
                        </span>
                        {this.state.currentQuestion && <h1 className="title">{this.state.currentQuestion.question}</h1>}
                        <div className="content" dangerouslySetInnerHTML={{ __html: this.state.currentQuestion ? this.state.currentQuestion.answer : '' }} />
                    </div>

                </div>
                </div>
            </>
        );
    }
}

export default FaqsList;