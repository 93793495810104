import Widget from './components/widget'

function App() {

  return (
    <div className="App">
      <Widget />
    </div>
  );
}

export default App;
